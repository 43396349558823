<template>
    <el-dialog title="编辑" width="60%" :close-on-click-modal="false" :visible.sync="visible">
        <el-descriptions title="订单信息" :column="2" class="b_b p_b1 m_b2">
            <el-descriptions-item label="订单编号">{{info.outTradeNo}}</el-descriptions-item>
            <el-descriptions-item label="下单时间">{{info.createTime}}</el-descriptions-item>
            <el-descriptions-item label="平台交易号">{{info.tradeNo}}</el-descriptions-item>
            <el-descriptions-item label="支付时间">{{info.payDate}}</el-descriptions-item>
            <el-descriptions-item label="订单状态">
                <el-tag size="small" v-if="info.payState">
                    {{$dictUtils.getDictLabel("payState",info.payState,'')}}
                </el-tag>
            </el-descriptions-item>
            <el-descriptions-item label="退款订单号" v-if="info.payState == 2 || info.payState == 3">
                {{info.outRequestNo}}
            </el-descriptions-item>
            <el-descriptions-item label="提交退款时间" v-if="info.payState == 2 || info.payState == 3">
                {{info.createOutRequestDate}}
            </el-descriptions-item>
            <el-descriptions-item label="退款完成时间" v-if="info.payState == 3">{{info.outRequestDate}}
            </el-descriptions-item>
            <el-descriptions-item label="总价（元）">{{info.money}}</el-descriptions-item>
            <el-descriptions-item label="发票类型">{{$dictUtils.getDictLabel("billType",info.billType,'')}}
            </el-descriptions-item>
            <el-descriptions-item label="税号" v-if="info.billType == 2">{{info.dutyParagraph}}</el-descriptions-item>
            <el-descriptions-item label="抬头" v-if="info.billType == 2">>{{info.lookUp}}</el-descriptions-item>
        </el-descriptions>
        <el-form size="small" :model="form" ref="form" label-width="100px" class="m_t1">
            <el-form-item label="二维码：" prop="threeQr">
                <Upload v-if="visible" :uploadAskObj="uploadRequire" @getDataList="getDataList"></Upload>
            </el-form-item>
            <el-form-item label="发票文件：" prop="billFile">
                <Upload v-if="visible" :uploadAskObj="uploadRequire2" @getDataList="getDataList2"></Upload>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="visible = false">关闭</el-button>
      <el-button size="small" type="primary" @click="doSubmit()" v-noMoreClick>确定</el-button>
    </span>
    </el-dialog>
</template>

<script>
    import Upload from '@/components/Upload/Upload'
    export default {
        components: {Upload},
        data() {
            return {
                visible: false,
                info: {},
                form: {
                    id: '',
                    threeQr: '',
                    billFile: '',
                },
                uploadRequire: {
                    fileType: ['jpg', 'png', 'jpeg', 'JPG', 'PNG', 'JPEG'],
                    showFile: true, // 是否显示已上传文件列表
                    count: 1, // 上传文件数量 0表示不限制文件的数量
                    listType: 'picture-card', // 文件类型
                    list: [],
                },
                uploadRequire2: {
                    fileType: ['pdf'],
                    showFile: true, // 是否显示已上传文件列表
                    count: 1, // 上传文件数量 0表示不限制文件的数量
                    listType: 'txt', // 文件类型
                    list: [],
                },
            }
        },
        methods: {
            // 初始化数据
            init(row) {
                this.uploadRequire.list = []
                this.uploadRequire2.list = []
                this.info = row
                this.visible = true
                this.$nextTick(() => {
                    this.recover(this.form, row);
                    if(row.threeQr && row.threeQr != null){
                        this.uploadRequire.list = [
                            {
                                name: '二维码',
                                url: row.threeQr
                            }
                        ]
                    }
                    if(row.billFile && row.billFile != null) {
                        this.uploadRequire2.list = [
                            {
                                name: '发票文件',
                                url: row.billFile
                            }
                        ]
                    }
                })
            },
            // 获取上传二维码数据
            getDataList(data) {
                if(data && data.response.data != '') {
                    this.form.threeQr = data.response.data
                } else {
                    this.form.threeQr = ''
                }
            },
            getDataList2(data) {
                if(data && data.response.data != '') {
                    this.form.billFile = data.response.data
                } else {
                    this.form.billFile = ''
                }
            },
            doSubmit() {
                this.$axios(this.api.order.updateOrder, this.form, 'post').then(data => {
                    this.loading = false
                    if (data && data.status) {
                        this.visible = false
                        this.$message.success(data.msg)
                        this.$emit('refreshDataList')
                    } else {
                        this.$message.error(data.msg)
                    }
                })
            },
        }
    }
</script>
